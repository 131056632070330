import { defineStore } from 'pinia'

const useTabsStore = defineStore('globalConfig', {
  // state：状态库、必须是函数，否则会报错。
  state: () => {
    return {
      // 右侧侧边栏状态
      globalConfig: false
    }
  },
  /**
   * 持久化存储插件
   * https://prazdevs.github.io/pinia-plugin-persistedstate/zh/
   */
  persist: {
    key: 'BANXI_MENU_TAGS',
    storage: sessionStorage,
    paths: ['globalConfig']
  },

  /**
   * getters
   * 计算属性，用于需要二次加工的属性, 与 vuex 几乎无区别
   */
  getters: {},

  /**
   * actions
   * 相当于vue组件中的 methods .可以通过this访问state数据，支持异步，async、await关键字
   */
  actions: {}
})

export default useTabsStore
